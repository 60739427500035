
const checkFileSize = (files) => {
    return new Promise((resolve, reject) => {
        const rejectList = []
        let fileList = Array.from(files)
        fileList.forEach((file, i) => {
            let size = file.size / 1024 / 1024
            if ((size) > 1) {
                alert(`${file.name} size is: ${size.toPrecision(2)}MB. Maximum upload file size is 1MB`);
                rejectList.push(i)
            }
            if (i == files.length - 1) {
                rejectList.forEach((el, i) => {
                    fileList.splice(el, 1)
                    if (i == rejectList.length - 1) { resolve(fileList) }
                })
            }
        })
    });
}

const setEventListeners = () => {
    function checkNumberOfImages(e, newFiles) {
        let list = new DataTransfer;
        const length = parseInt(e.target.getAttribute('length'));
        if (newFiles.length > (length)) {
            alert("Only 5 images accepted.");
            console.log('list', list)
            newFiles.forEach((file, i) => {
                i < length ? list.items.add(newFiles[i]) : ''
            })


            document.getElementById('images-upload').files = list.files
            e.preventDefault();
        } else {
            newFiles.forEach((file, i) => {
                list.items.add(newFiles[i])
            })
            document.getElementById('images-upload').files = list.files
            e.preventDefault();
        }

    };

    function limitImagesNo(e) {
        console.log("upload", e)
        const fileList = e.target.files
        checkFileSize(fileList).then(newFiles => {
            checkNumberOfImages(e, newFiles)
        })
    }

    function limitSize(e) {
        const fileList = e.target.files
        checkFileSize(fileList).then(newFiles => {
            console.log("newfiles", newFiles)
            let list = new DataTransfer;
            if (newFiles.length > 0) {
                list.items.add(newFiles[0])
                document.getElementById('cover-image-upload').files = list.files
                e.preventDefault();
            } else {
                document.getElementById('cover-image-upload').files = list.files
                e.preventDefault();
            }
        })
    }

    const imagesUploader = document.getElementById("images-upload");
    if (imagesUploader) {

        imagesUploader.addEventListener("change", limitImagesNo, false)
    }

    const coverImageUploader = document.getElementById("cover-image-upload");
    if (coverImageUploader) {
        coverImageUploader.addEventListener("change", limitSize, false)
    }

    function showCoverImageUpload() {
        const upload = document.getElementById("cover-image-upload");
        const thumbnail = document.getElementById("old-cover-image");
        upload.classList.remove("d-none");
        thumbnail.classList.add("d-none");

    }
    function showImagesUpload() {
        const indexAttri = this.getAttribute('image');
        const index = parseInt(this.getAttribute('image'));
        const upload = document.getElementById("images-upload");
        const uploader = document.getElementById('images-upload')
        const thumbnail = document.getElementsByClassName("old-image")[index];
        // upload.classList.remove("d-none");
        const removedImages = document.getElementById('removedImages');
        removedImages.value = removedImages.value.concat(',').concat(indexAttri)
        uploader.setAttribute("length", parseInt(uploader.getAttribute.length) + 1)
        console.log("uploader", uploader)
        thumbnail.classList.add("d-none");

    }
    const el = document.getElementById("remove-cover-image");
    if (el) {
        el.addEventListener("click", showCoverImageUpload, false);
    }
    // const removedImages = document.getElementById("removed-images");
    const removeImages = document.getElementsByClassName("remove-images");
    if (removeImages) {
        for (var i = 0; i < removeImages.length; i++) {
            removeImages[i].addEventListener("click", showImagesUpload, false);
        }
    }
};


export { setEventListeners };


