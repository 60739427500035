
import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import 'mapbox-gl/dist/mapbox-gl.css';
let currentMarkers = []

function removeMarker() {
    if (currentMarkers !== null) {
        for (let i = currentMarkers.length - 1; i >= 0; i--) {
            currentMarkers[i].remove();
        }
    }
}
// function updateFormLngLat(lngLat) {
//     longitudeField.value = lngLat.lng
//     latitudeField.value = lngLat.lat
//     longitudeField.disabled = false
//     latitudeField.disabled = false
//     addressField.disabled = false
//     addressField.placeholder = "Please enter address manually."
// }

// initialize map
function markerOnClick(map, geocoder) {
    map.on('click', (e) => {
        geocoder.clear()
        removeMarker()
        updateFormLngLat(e.lngLat)
        let marker = new mapboxgl.Marker({ draggable: true }).setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(map)
        currentMarkers.push(marker)
        function onDragEnd() {
            const lngLat = marker.getLngLat();
            updateFormLngLat(lngLat)
        }
        marker.on('dragend', onDragEnd);
    })
}

const initMapbox = () => {
    const mapElement = document.getElementById('map');
    const mapElementShowPage = document.getElementById('show-map');
    if (mapElementShowPage) {
        showPageMap(mapElementShowPage)
    }
    if (mapElement) { // only build a map if there's a div#map to inject into
        formMap(mapElement)
    }

};

// for show page map

const showPageMap = (mapElementShowPage) => {
    const marker = JSON.parse(mapElementShowPage.getAttribute('data-marker'))
    mapboxgl.accessToken = mapElementShowPage.dataset.mapboxApiKey;
    const showmap = new mapboxgl.Map({
        container: 'show-map',
        style: 'mapbox://styles/mapbox/streets-v10',
        center: [marker.lng, marker.lat],
        zoom: 13
    });
    new mapboxgl.Marker().setLngLat([marker.lng, marker.lat]).addTo(showmap)
}

// for maps on forms

const formMap = (mapElement) => {
    mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
    const formFor = mapElement.getAttribute('data-for');
    const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: 'Enter location',
        language: 'en'
    })
    if (formFor == "edit") {
        editMap(geocoder, mapElement)
    } else {
        createMap(geocoder, mapElement)
    }
}

// create form

const editMap = (geocoder, mapElement) => {
    const dataMarker = JSON.parse(mapElement.getAttribute('data-marker'))
    const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v10',
        center: [dataMarker.lng, dataMarker.lat],
        zoom: 13
    });
    let marker = new mapboxgl.Marker().setLngLat([dataMarker.lng, dataMarker.lat]).addTo(map)
    currentMarkers.push(marker)
    function onDragEnd() {
        const lngLat = marker.getLngLat();
        updateFormLngLat(lngLat)
    }
    marker.on('dragend', onDragEnd);
    markerOnClick(map, geocoder)
    map.addControl(geocoder)
    // geocoder.addTo('#map-input');
    geocoder.on('result', function (e) {
        setAddress(e)
    });
}

// edit form

const createMap = (geocoder, mapElement) => {
    const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v10',
        center: [12.4964, 41.9028],
        zoom: 13
    });
    markerOnClick(map, geocoder)
    map.addControl(geocoder)
    // geocoder.addTo('#map-input');
    geocoder.on('result', function (e) {
        setAddress(e)
    });
}

function updateFormLngLat(lngLat) {
    document.getElementById('listing_address').disabled = false
    document.getElementById('listing_longitude').disabled = false
    document.getElementById('listing_latitude').disabled = false
    document.getElementById('listing_longitude').value = lngLat.lng
    document.getElementById('listing_latitude').value = lngLat.lat
    document.getElementById('listing_address').placeholder = "Please enter address manually."
}

const setAddress = (e) => {
    removeMarker()

    document.getElementById('listing_address').disabled = false
    document.getElementById('listing_longitude').disabled = false
    document.getElementById('listing_latitude').disabled = false
    document.getElementById('listing_address').value = e.result['place_name']
    document.getElementById('listing_longitude').value = e.result['geometry']['coordinates'][0]
    document.getElementById('listing_latitude').value = e.result['geometry']['coordinates'][1]
}

export { initMapbox };


