const setAppointmentCalendar = () => {
    const selectAppointment = () => {
        const dateCarosel = document.getElementById('dateCarosel')
        const contentCarousel = document.getElementById('contentCarousel')
        if (dateCarosel) {
            dateCarosel.addEventListener('slid.bs.carousel', function (e) {
                const contentCarouselItems = document.getElementsByClassName('content carousel-item')
                const dateIndex = document.getElementsByClassName('date-carousel carousel-item active')[0].getAttribute('curr')
                const activeIndex = parseInt(dateIndex)
                console.log(activeIndex, document.getElementsByClassName('date-carousel carousel-item active')[0].getAttribute('curr'), "test carousel", contentCarouselItems)
                const prevActive = document.getElementsByClassName('content carousel-item active')[0]
                if (prevActive) { prevActive.classList.remove("active") }
                contentCarouselItems[activeIndex].classList.add("active");
            })
        }
    }
    function toggleSlot() {
        document.body.style.color = "purple";
        return false;
    }
    const slotSelector = () => {
        const slots = document.getElementsByClassName('slot-btn')
        if (slots) {
            for (var i = 0; i < slots.length; i++) {
                slots[i].addEventListener('click', function () {
                    const time = this.getAttribute('time');
                    const date = document.getElementById('date').innerHTML;
                    const inputStartTime = document.getElementById('inputStartTime');
                    const createOrDestroy = document.getElementById('createOrDestroy');
                    // const listing_id = document.getElementById('listing_id');
                    const inputDate = document.getElementById('inputDate');
                    const active = this.classList.contains("btn-slot-selected");
                    if (active) {
                        this.classList.add('btn-slot');
                        this.classList.remove('btn-slot-selected');
                        inputStartTime.value = time
                        createOrDestroy.value = 'false'
                        inputDate.value = date
                    } else {
                        this.classList.remove('btn-slot');
                        this.classList.add('btn-slot-selected');
                        inputStartTime.value = time
                        createOrDestroy.value = 'true'
                        inputDate.value = date
                    }
                }, false);
            }
        }
        // slots.forEach(el => {
        //     el.onclick = toggleSlot;
        //     // el.addEventListener("click", toggleSlot, false);
        // });
    }

    slotSelector()
    selectAppointment()
};


export { setAppointmentCalendar };


