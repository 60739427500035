// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
console.log("packs app js loaded");

import "bootstrap"
import "../stylesheets/application.scss"
require("@rails/ujs").start()
// require("trix");
// require("@rails/actiontext");
import "trix/dist/trix.css";

require("turbolinks").start()
require("@rails/activestorage").start()
// import * as ActiveStorage from "@rails/activestorage"

document.addEventListener('turbolinks:load', () => {
    initMapbox();
    setEventListeners();
    setAppointmentCalendar();
})

import "channels"
// import '../js/bootstrap_js_files.js'
import "@fortawesome/fontawesome-free/js/all";
require("bootstrap")
import { initMapbox } from '../plugins/init_mapbox';
import { setEventListeners } from '../js/custom';
import { setAppointmentCalendar } from '../js/appointment';

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
global.toastr = require("toastr")

// import "../stylesheets/application"
